import ContentContainer from "../elements/ContentContainer";
import ContentHeader from "../elements/ContentHeader";
import ContentImage from "../elements/ContentImage";
import ContentLink from "../elements/ContentLink";
import ContentParagraph from "../elements/ContentParagraph";

function Elsewhere() {
  return (
    <ContentContainer title="Elsewhere">
      <ContentImage src="backinblack.png">
        <small>Art by <ContentLink href="https://www.fiverr.com/dewikecoa">dewikecoa</ContentLink></small>
      </ContentImage>
      <ContentHeader>Social Media</ContentHeader>
      <ContentParagraph>
        <ul>
          <li><ContentLink href="https://twitter.com/squam0"><i class="fa-brands fa-twitter px-5"></i>Twitter: @squam0</ContentLink></li>
          <li><ContentLink href="https://bsky.app/profile/lizard.fun"><i class="fa-solid fa-cloud px-5"></i>Bluesky: @lizard.fun</ContentLink></li>
          <li><ContentLink href="http://www.furaffinity.net/user/squam/"><i class="fa-solid fa-dragon px-5"></i>FurAffinity: squam</ContentLink></li>
          <li><ContentLink href="https://rateyourmusic.com/~squam"><i class="fa-solid fa-music px-5"></i>Rate Your Music: squam</ContentLink></li>
        </ul>
      </ContentParagraph>
    </ContentContainer>
  );
}

export default Elsewhere;
